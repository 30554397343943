
<template>
  <div class="customer-list">
    <div class="listHeader SecondNav">
      <el-tabs v-model="activeName" type="card" @tab-click="activeNameClick">
        <el-tab-pane label="全部" name="0" />
        <el-tab-pane label="客户" name="1" />
        <el-tab-pane label="供应商" name="2" />
      </el-tabs>
    </div>
    <div class="p-lr-20">
      <div>
        <div class="searchView  flex a-center m-bottom-10 j-between">
          <div class="flex">
            <div v-if="$minCommon.checkMeau('qylbexport', userBottons)" class="out bg p-lr-10 flex a-center m-right-10 pointer ModelBttton-white-30" @click="exportData">
              <i class="el-icon-upload2 f15  p-right-10 " />   导出
            </div>
            <div v-if="$minCommon.checkMeau('qylbfiled', userBottons)" class="out p-lr-10 flex a-center ModelBttton-white-30" @click="() => $refs.FieldManagement.openSetFieldManagement()">
              <i class="el-icon-setting f15 p-right-5" />   字段管理
            </div>
          </div>
          <div v-if="$minCommon.checkMeau('qylbview', userBottons)" class="flex m-left-10 SearchBox_30" style="flex:1">
            <el-input v-model="searchWords.KeyWords" placeholder="可通过企业名称、企业简称、社会统一信用代码、联系人名称、申请部门、申请人搜索" class="rule-input-edit">
              <template slot="prepend">
                <div class="pointer">
                  <i class="el-icon-s-operation m-right-5" @click="Adsearch" />
                  <span @click="Adsearch">高级搜索</span>
                </div>
              </template>
              <div slot="append" @click="searchGetTableDataAsync">搜  索</div>
            </el-input>
          </div>
        </div>
        <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,searchWords,adSearchForm,'ApplyDate','ApplyDateBegin','ApplyDateEnd')" />

        <el-table
          ref="multipleTable"
          v-loading="tableLoading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="tableData"
          tooltip-effect="dark"
          height="calc(100vh - 315px)"
          show-header
          row-key="id"
          border
          @select-all="selectAll"
          @select="select"
          @filter-change="testChange"
        >
          <af-table-column
            fixed="left"
            type="selection"
            width="40"
          />

          <el-table-column
            label="当前状态"
            width="90"
            prop="Status"
            column-key="StatusString"
            :filter-method="filterHandler"
            :filters="[
              { text: '审核中', value: 0 },
              { text: '正常', value: 1 },
              { text: '已禁用', value: 2 },
              { text: '已驳回', value: -1 },
            ]"
          >
            <template slot-scope="scope">
              <!-- <el-button v-if="scope.row.ApproveState === 2" size="mini" type="success" icon="el-icon-check" circle />
              <el-button v-if="scope.row.ApproveState === 4" type="info" icon="el-icon-minus" circle /> -->
              {{ scope.row.StatusString }}
            </template>
          </el-table-column>
          <template v-for="(item,index) in tableListJson.customerist">
            <af-table-column
              v-if="item.isChecked && item.prop !== 'TaxRate' && item.prop !== 'ApplyDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            />
            <af-table-column
              v-if="item.isChecked && item.prop === 'ApplyDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ $minCommon.setDateFormate(scope.row.ApplyDate) }}
              </template>
            </af-table-column>

            <af-table-column
              v-if="item.isChecked && item.prop === 'TaxRate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.TaxRate === -1 ? '' : scope.row.TaxRate*100 }}
              </template>
            </af-table-column>

          </template>
          <af-table-column
            fixed="right"
            prop="address"
            align="center"
            label="操作"
            width="50"
          >
            <!-- slot-scope="scope" -->
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="hover"
                :width="50"
                popper-class="test_pop_view"
              >
                <div class="DropDownList">
                  <div v-if="$minCommon.checkMeau('qylbview', userBottons)" class="OperationButton">
                    <router-link :to="'/merchants/detail/' + scope.row.IDX + '/1'">
                      <el-link :underline="false"><i class="el-icon-search p-right-10" />查看</el-link>
                    </router-link>
                  </div>
                  <div v-if="$minCommon.checkMeau('qylbedit', userBottons)" class="OperationButton" @click="toEdit(scope.row)">
                    <!-- @click="Turn('/product/productEdit?idx='+scope.row.IDX)" -->
                    <el-link :underline="false"><i class="el-icon-edit p-right-10" />编辑</el-link>
                  </div>
                  <!-- <div class="min-border-bottom m-tb-10">
                    <i class="el-icon-delete p-right-10" />
                    <span><el-link type="primary" :underline="false">删除</el-link></span>
                  </div> -->
                  <div v-if="$minCommon.checkMeau('qylbdisable', userBottons) || $minCommon.checkMeau('qylbenable', userBottons)" class="OperationButton" @click="disabled( scope.row)">
                    <el-link :underline="false"><i class="el-icon-switch-button p-right-10" />{{ scope.row.Status === 1 ? '禁用' : '启用' }}</el-link>
                  </div>
                  <div v-if="$minCommon.checkMeau('qylbdownload', userBottons)" class="OperationButton" @click="downItem( scope.row)">
                    <el-link :underline="false"><i class="el-icon-download p-right-10" />下载</el-link>
                  </div>
                </div>
                <i slot="reference" class="el-icon-more" />
              </el-popover>
            </template>
          </af-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            :ref="paginationName"
            :total="tableTotalSize"
            :pager-count="pagerCount"
            :page-size="tablePageSize"
            :background="tableBackground"
            layout="prev, pager, next, jumper"
            @current-change="handleCurrentChange($event)"
          />
        </div>
      </div>
    </div>
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
    <Adsearch :show="isShow" :form="formSearch" :ad-search-form="adSearchForm" @cancel="(e) => isShow = e" @searchAd="searchAd" />
    <FieldManagement ref="FieldManagement" action-name="GetSellOrCustomCompanyList" :list="tableListJson.customerist" name="customerist" :change-list-table="changeListTable" />
    <!-- <FieldManagement ref="FieldManagement" action-name="GetSellOrCustomCompanyList" :list="$store.state.index.json.customerist" name="customerist" :change-list-table="changeListTable" /> -->

  </div>
</template>
<script>
import customerList from '@/minxin/merchants/customerList.js'
export default customerList
</script>
<style scoped lang='scss'>
@import '@/style/search.scss';
</style>
<style lang='scss'>
.advanser .el-form-item__label {
  font-size: 10px
}
.pointer {
    cursor: pointer
}
.el-button.is-circle {
  padding: 1px
}
.el-button--mini.is-circle {
  padding: 1px
}
.merchants{
  position: relative;
  .allChilce{
      height: 25px;
      line-height: 25px;
      .active{
        color: #333;
      }
      .nomr{
         color: #ccc;
      }
  }
  .main_flex{
    // width: 100%;
    min-height: 400px;
    .left{
      width: 400px;
    }
    .right{
      flex: 1;
      min-width: 400px;
    }
  }
  .custom-class{
    .main{
      display: flex;
      justify-content: space-between;
       height:calc(100vh - 20vh);
      .left{
        min-width: 200px;
        background-color: red;
        padding: 5px;
        overflow:auto;
        .img_view{
            width: 100%;
            height: 200px;
            background-color: #fff;
        }
      }
      .left::-webkit-scrollbar {
        display: none;
      }
      .mid{
        flex: 1;
        background-color: green;
      }
      .right{
        min-width: 300px;
        background-color: yellow;
      }
    }
    .el-dialog__body{
      padding: 20px;
    }
    .el-dialog__header{
      padding: 0;
      display: none;
    }
  }
  .cropper-content {
    .cropper {
      //  min-width: 500px;
       width: 100%;
       height:  70vh;
    }
}
.absol{
  position: absolute;
  left: 0;
}

}
.test_pop_view{
  min-width: 60px;
}
</style>

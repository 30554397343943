import tableMixin from '@/minxin/table-minxin'
import Adsearch from '@/components/Adsearch'
import root from '@/minxin/root'
const customerList = {
  name: 'customerList',
  components: {
    Adsearch
  },
  mixins: [tableMixin, root],
  data() {
    return {
      root_name: 'qylb',
      root_msg: '客商列表',
      activeName: '0',
      searchWords: {
        PageIndex: 1,
        PageSize: this.tablePageSize,
        KeyWords: '',
        ApplyDateBegin: '',
        ApplyDateEnd: '',
        ApplyUser: '',
        ApplyDepartment: '',
        CompanyAbbreviation: '',
        AuthorizedPerson: '',
        PaymentCollectionDays: '',
        PayType: '',
        TaxRate: '',
        Street: '',
        Remark: '',
        ContactTel: '',
        CompanyType: 0,
        ContactName: '',
        CreditCode: '',
        CompanyNo: '',
        CompanyName: '',
        Status: 0
      },
      adSearchForm: [
        { name: '申请日期', date: true, value: 'ApplyDate' },
        { name: '企业名称', value: 'CompanyName' },
        { name: '企业简称', value: 'CompanyAbbreviation' },
        { name: '信用代码', value: 'CreditCode' },
        { name: '联系人', value: 'ContactName' },
        { name: '企业编号', value: 'CompanyNo' },
        { name: '被授权人', value: 'AuthorizedPerson' },
        { name: '客户分类', check: true, checkBox: [{ name: '客户' }, { name: '供应商' }], value: 'CompanyType' },
        { name: '申请部门', value: 'ApplyDepartment' },
        { name: '申请人', value: 'ApplyUser' },
        { name: '当前状态', value: 'Status' },
        { name: '回款账期', value: 'PaymentCollectionDays' },
        { name: '付款方式', value: 'PayType' },
        { name: '开票税率', value: 'TaxRate' },
        { name: '详细地址', value: 'Street' },
        { name: '备注', value: 'Remark' }
      ],
      formSearch: {
        ApplyDate: '',
        CompanyName: '',
        CompanyAbbreviation: '',
        CreditCode: '',
        ContactName: '',
        CompanyNo: '',
        AuthorizedPerson: '',
        CompanyType: [],
        ApplyUser: '',
        ApplyDepartment: '',
        Status: '',
        PaymentCollectionDays: '',
        TaxRate: '',
        PayType: '',
        Street: '',
        Remark: ''
      },
      isShow: false,
      selectsArr: [],
      selects: []
    }
  },
  mounted() {
    // 获取按钮权限
    // this.getBottons('qylb')
    // console.log(this.$minCommon.SetTableHeight(330) / 27)
  },
  watch: {
    selects(a) {
      this.selectsArr = []
      if (a.length === 0) return this.selectsArr = []
      a.map(item => {
        this.selectsArr.push(item.IDX)
      })
      console.log(this.selectsArr)
    }
  },
  methods: {
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    // 首营下载
    downItem(row) {
      console.log(row)
      if (row.FileJsonStr === null) return this.$message.error('无数据可下载')
      this.$api.DownloadFirstCampZip({
        JsonStr: row.FileJsonStr
      }).then(res => {
        console.log(res)
        this.$minCommon.downloadFile(res.Message, '.zip')
      })
    },
    getTypeNum(arr) {
      if (arr.indexOf('客户') !== -1 && arr.indexOf('供应商') !== -1) {
        return 4
      }
      if (arr.indexOf('客户') !== -1 && arr.length === 1) {
        return 1
      }
      if (arr.indexOf('供应商') !== -1 && arr.length === 1) {
        return 2
      }
      if (arr.length === 0) {
        return 4
      }
    },
    async searchAd() {
      for (const name in this.formSearch) {
        this.searchWords[name] = this.formSearch[name]
      }
      console.log(this.searchWords)
      this.searchWords.ApplyDateBegin = this.searchWords.ApplyDate[0]
      this.searchWords.ApplyDateEnd = this.searchWords.ApplyDate[1]
      this.searchWords.CompanyType = this.getTypeNum(this.searchWords.CompanyType)
      console.log(this.searchWords)
      this.setKeysData(this.searchWords, this.adSearchForm) // 展示搜索条件
      const response = await this.$api.GetSellOrCustomCompanyList(this.searchWords)
      // console.log(response)
      const { Message, Total } = response
      this.tableData = Message
      this.tableTotalSize = Total
      // for (const name in this.searchWords) {
      //   this.searchWords[name] = ''
      // }
      // for (const name in this.formSearch) {
      //   this.searchWords[name] = ''
      // }
      this.isShow = false
    },
    Adsearch() {
      this.isShow = false
      this.isShow = true
    },
    testChange(e) {
      console.log(e.StatusString)
      let Status = ''
      e.StatusString.forEach(item => {
        Status += `${item},`
      })
      this.searchWords.Status = Status
      this.getTableDataAsync()
    },
    filterHandler(value, row, column) {
      // [{text: '正常', value: 1}, {text: '审批中', value: 0}, {text: '已驳回', value: -1}, {text: '已禁用', value: 2}]
      const property = column['property']
      console.log('asddasdassad', row[property], value)
      return row[property] === value
    },
    toEdit(row) {
      this.$router.push({
        name: 'Edit1',
        params: { IDX: row.IDX, active: 1, TYPE: '1' }
      })
    },
    disabled(row) {
      console.log(row)
      this.$confirm(`${row.Status === 1 ? '确认禁用该项?' : '确认启用该项'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DisableCompanySupplier({ IDX: row.IDX }).then(res => {
          if (res.RetCode === '0') {
            this.$message({
              type: 'success',
              message: row.Status === 1 ? '禁用成功!' : '启用成功!'
            })
            return this.refreshCurrentChange()
          }
          return this.$message.error(row.Status === 1 ? '禁用成功!' : '启用成功!')
        })
      })
    },
    CompanyTypeformatter(row, column) {
      return row.CompanyType === 1 ? '客户' : row.CompanyType === 2 ? '供应商' : '客户/供应商'
    },
    exportData() {
      // ExportSellOrCustomCompanyListByUserField
      this.searchWords.FieldStr = JSON.stringify(this.tableListJson[ this.$refs.FieldManagement.name ])
      this.searchWords.SelectIDList = this.selectsArr
      this.$refs.ExportLoading.open()
      this.$api.ExportSellOrCustomCompanyListByUserField(this.searchWords).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        const name = this.$minCommon.getExportName('客商列表')
        this.$refs.ExportLoading.success(res.Message, name)
        return this.$minCommon.downloadFile(res.Message, name)
      })
    },
    OpenAdvanSearch() {

    },
    activeNameClick(e) {
      console.log(e.index)
      if (e.index === '0') {
        this.searchWords.CompanyType = 0
        this.refreshCurrentChange()
      }
      if (e.index === '1') {
        this.searchWords.CompanyType = 1
        this.getTableDataAsync(1, this.tablePageSize)
      }
      if (e.index === '2') {
        this.searchWords.CompanyType = 2
        this.getTableDataAsync(1, this.tablePageSize)
      }
    },
    async getTableDataAsync(page, pageSize) {
      this.tableLoading = true
      this.searchWords.PageIndex = page || 1
      this.searchWords.PageSize = this.tablePageSize
      const response = await this.$api.GetSellOrCustomCompanyList(this.searchWords)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      this.tableLoading = false
      console.log('获取列表', response)
      return response
    }
  }

}

export default customerList
